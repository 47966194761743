.App {
  text-align: center;
  align-content: center;
}


.ListItem {
  font-size: 1em;
  display: flexbox;
  width: 80%;
}

.strikethrough {
  text-decoration: line-through;
}

.Button{
  height: fit-content;
  margin-left: .5em;
}

.TaskList{
  list-style: none;
  padding-left: 0;
  width: 100%;
}

.NewTaskButton{
  height: fit-content;
  width: 50%;
  margin-top: .5em;
}