body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
  align-content: center;
}


.ListItem {
  font-size: 1em;
  display: flexbox;
  width: 80%;
}

.strikethrough {
  text-decoration: line-through;
}

.Button{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: .5em;
}

.TaskList{
  list-style: none;
  padding-left: 0;
  width: 100%;
}

.NewTaskButton{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 50%;
  margin-top: .5em;
}
